import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from './Table';
import { Api } from '../api';
import { Space } from 'antd';
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from './BasicTableFilter';




const columns = [
    {
        title: "id",
        sorter: true,
        dataIndex: "_id",
        width: '20%',
        key: "_id",
        render: (text: string) => {
            return (<Link to={`/currencies/update/${text}`}>{text}</Link>)
        }
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: "name",
        sorter: true,
        width: '20%',
    },
];

const CurrencyTable = () => {
    return (
        <Space style={{ width: '100%' }} direction="vertical">
            <Table
                tableId={ "currenciesTable" }
              deleteApiCall={Api.currencies.delete}
                allowDelete={true}
                restoreApiCall={Api.currencies.restore}
                actions={(record: any) => (
                    <Link to={`/currencies/update/${record._id}`}>View</Link>
                )}
                Filter={BasicTableFilter}
             apiCall={Api.currencies.table} columns={columns} />
        </Space>
    )
};

export default CurrencyTable;