import React, { useEffect, useRef } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { ReactElement } from "react";
import { Spin } from "antd";

const render = (status: Status): ReactElement => {
  if (status === Status.FAILURE) return <p>error loading</p>;
  return <Spin />;
};

const Marker: React.FC<any> = (options) => {
  const [marker, setMarker] = React.useState<any>();

  React.useEffect(() => {
    if (!marker) {
      //@ts-ignore
      setMarker(new (google as any).maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

function MyMapComponent({ center, zoom, children }: { center: any; zoom: number; children: any }) {
  const ref = React.useRef<HTMLDivElement>(null);
  //@ts-ignore
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      console.log("setting map");
      //@ts-ignore
      setMap(new window.google.maps.Map(ref.current, { center, zoom }));
    }
  }, [ref, map]);

  return (
    <div style={{ height: "100%" }} ref={ref} id="map">
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          //@ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </div>
  );
}
const GoogleMap = ({ lat = 0, long = 0 }) => {
  const center = { lat: lat, lng: long };
  const zoom = 18;
  return (
    <Wrapper apiKey={""} render={render}>
      {/* //@ts-ignore */}
      <MyMapComponent center={center} zoom={zoom}>
        <Marker position={center} />
      </MyMapComponent>
    </Wrapper>
  );
};

export default GoogleMap;
