import { Button, Col, Input, message, Result, Row, Space } from "antd";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";

const ContactFormForm = () => {
  let { id }: any = useParams();
  // const [notFound, setNotFound] = useState(false);
  const { handleSubmit, errors, control, reset } = useForm();
  let history = useHistory();
  const onSuccess = () => {
    message.success("Saved");
    history.push("/contactForms");
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.contactForms.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.contactForms.create,
    updateApiCall: Api.contactForms.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="primary">
            <Link to="/contactForms">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form className="white-container" onSubmit={handleSubmit(submit)}>
      <Space size="middle" style={{ width: "100%" }} direction="vertical">
        <Row style={{ width: "100%" }} gutter={12}>
          <Col span={6}>
            <label htmlFor={"firstName"}>First Name</label>
            <Controller
              control={control}
              as={<Input id={"firstName"} />}
              rules={{ required: true }}
              name="firstName"
            />
            <FieldErrorMessage errors={errors} name="lastName" />
          </Col>
          <Col span={6}>
            <label htmlFor={"lastName"}>Last Name</label>
            <Controller
              control={control}
              as={<Input id={"lastName"} />}
              rules={{ required: true }}
              name="lastName"
            />
            <FieldErrorMessage errors={errors} name="lastName" />
          </Col>
          <Col span={6}>
            <label htmlFor={"phone"}>Phone</label>
            <Controller
              control={control}
              as={<Input id={"phone"} />}
              rules={{ required: true }}
              name="phone"
            />
            <FieldErrorMessage errors={errors} name="phone" />
          </Col>
          <Col span={6}>
            <label htmlFor={"email"}>Email</label>
            <Controller
              control={control}
              as={<Input id={"email"} />}
              rules={{ required: true }}
              name="email"
            />
            <FieldErrorMessage errors={errors} name="email" />
          </Col>
          <Col span={12}>
            <label htmlFor={"message"}>Message</label>
            <Controller
              control={control}
              as={<Input.TextArea id={"message"} />}
              rules={{ required: true }}
              name="message"
            />
            <FieldErrorMessage errors={errors} name="message" />
          </Col>
        </Row>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>
        Submit
      </Button>
    </form>
  );
};

export default ContactFormForm;
