import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from './Table';
import { Api } from '../api';
import { Button, Popconfirm, Space } from 'antd';
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from './BasicTableFilter';
import { Helpers } from '../helpers';




const columns = [
  {
    title: "id",
    sorter: true,
    dataIndex: "_id",
    key: "_id",
    render: (text: string) => {
      return (<Link to={`/websiteUsers/update/${text}`}>{text}</Link>)
    }
  },
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: "firstName",
    sorter: true,

  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: "lastName",
    sorter: true,
  },
  {
    title: 'Phone',
    dataIndex: 'mobileNumber',
    key: "mobileNumber",
    sorter: true,
    render: (number: any) => {
      console.log(number);
      return (number.number);
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: "email",
    sorter: true,
  },
  {
    title: 'createdAt',
    dataIndex: 'createdAt',
    sorter: true,
    render: (date: Date) => {
      return <p title={Helpers.formatDateFull(date)}>{Helpers.formatDate(date)}</p>;
    },
  },
  {
    title: 'Is Driver',
    dataIndex: 'isDriver',
    key: "isDriver",
    sorter: true,
    render: (v: boolean) => {
      return v ? "Yes" : "No"
    },
  },
];
const default_definitions = [
  {
    key: '_id',
    type: 'number',
    label: "ID",
    span: 3,
  },
  {
    key: 'firstName',
    type: 'search',
    label: "First Name",
    span: 3,
  },
  {
    key: 'lastName',
    type: 'search',
    label: "Last Name",
    span: 3,
  },
  {
    key: 'email',
    type: 'search',
    label: "Email",
    span: 3,
  },
];

const WebsiteUserTable = () => {
  return (
    <Space style={{ width: '100%' }} direction="vertical">
      <Table
        tableId={"websiteUsersTable"}
        deleteApiCall={Api.websiteusers.delete}
        allowDelete={false}
        restoreApiCall={Api.websiteusers.restore}
        // actionsWidth={undefined}
        actions={(record: any, refreshData: any) => (
          <div style={{ width: "100%" }}>
            {
              record.isDriver ? <Popconfirm
                title="remove this user right to post ?"
                onConfirm={async () => {
                  await Api.websiteusers.toggleIsDriver(record._id);
                  refreshData();
                }}
                okText="Yes"
                cancelText="No"
              >
                <a href="#">Remove As Driver</a>
              </Popconfirm> : <Popconfirm
                title="Set this user as driver ?"
                onConfirm={async () => {
                  await Api.websiteusers.toggleIsDriver(record._id);
                  refreshData();
                }}
                okText="Yes"
                cancelText="No"
              >
                  <a href="#">Set As Driver</a>
                </Popconfirm>
            }
          </div>
        )}
        Filter={BasicTableFilter}
        filterDefinitions={default_definitions}
        apiCall={Api.websiteusers.table} columns={columns}
      />

    </Space>
  )
};

export default WebsiteUserTable;