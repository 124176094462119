import { Button, Checkbox, Col, DatePicker, Input, InputNumber, message, Result, Row, Select, Space } from "antd";
import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";
import _ from "lodash";
import moment from "moment";
import { useCategoriesList } from "../hooks/useCategoriesList";

const PromoCodeForm = () => {
    let { id }: any = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset, setValue } = useForm({ defaultValues: { validity: { from: new Date(), to: new Date() }, appliesOncePerOrder: true, type: "percentage", appliesTo: "allItems" } });
    const [categories, loadingCategories] = useCategoriesList();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/promoCodes')
    }
    // const imageFields = [
    //     {
    //         name: "image",
    //         singleValue: true,
    //     }
    // ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.promoCodes.single, id, setValue: reset,
        //  imageFields
    })
    const type = useWatch({ control, name: "type" });
    const appliesTo = useWatch({ control, name: "appliesTo" });
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.promoCodes.create,
            updateApiCall: Api.promoCodes.update,
            onSuccess,
            //  imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/promoCodes">Back to list</Link>
            </Button>}
        />)

    const onGenerateRandomCode = () => {
        setValue("code", _.times(6, () => _.random(35).toString(36)).join(''));
    }
    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row align="bottom" style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"code"}>Code</label>
                        <Controller
                            control={control}
                            as={<Input id={"code"} />}
                            rules={{ required: true }}
                            name="code"
                        />
                        <FieldErrorMessage errors={errors} name="code" />
                    </Col>
                    <Col span={2} >
                        <Button onClick={onGenerateRandomCode} type="dashed">Generate Random Code</Button>
                    </Col>

                </Row>
                <Row>
                    <Col span={6}>
                        <label htmlFor={"type"}>Type</label>
                        <Controller
                            control={control}
                            as={<Select defaultValue={"percentage"} options={[{ label: "Percentage", value: "percentage" }, { label: "Fixed Amount", value: "fixedAmount" }]} optionFilterProp="label"
                                allowClear style={{ width: "100%" }} />}
                            rules={{ required: false }}
                            name="type"
                        />
                        <FieldErrorMessage errors={errors} name="type" />
                    </Col>
                </Row>
                <Row gutter={6}>
                    {
                        type === "percentage" &&
                        <Col span={4}>
                            <label htmlFor={"value"}>Value</label>
                            <Controller
                                control={control}
                                as={<InputNumber formatter={value => `${value}%`}
                                    parser={(value: any) => value.replace('%', '')} min={1} max={100} style={{ width: "100%" }} id={"value"} />}
                                name="value"
                            />
                            <FieldErrorMessage errors={errors} name="value" />
                        </Col>
                    }
                    {
                        type === "fixedAmount" &&
                        <>
                            <Col span={4}>
                                <label htmlFor={"value"}>Value</label>
                                <Controller
                                    control={control}
                                    as={<InputNumber formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')} min={1} style={{ width: "100%" }} id={"value"} />}
                                    name="value"
                                />
                                <FieldErrorMessage errors={errors} name="value" />
                            </Col>


                        </>
                    }

                </Row>
                {
                    type === "fixedAmount" &&
                    <Row>
                        <Col span={24} >
                            <label style={{ display: "block" }} htmlFor={"appliesOncePerOrder"}>Applies Once Per Order</label>
                            <Controller
                                control={control}
                                render={({ onChange, onBlur, value }) => {
                                    return (
                                        <Checkbox
                                            checked={value}
                                            onChange={(e) => {
                                                onChange(e.target.checked)
                                            }}
                                        />
                                    )
                                }}
                                name="appliesOncePerOrder"
                            />
                        </Col>
                    </Row>
                }
                <Row gutter={4}>
                    <Col span={4}>
                        <label htmlFor={"validity.from"}>Valid From</label>
                        <Controller
                            control={control}
                            render={(
                                { onChange, onBlur, value, name }
                            ) => (
                                <DatePicker
                                    onBlur={onBlur}
                                    style={{ width: "100%" }}
                                    value={moment(value)}
                                    onChange={(e) => onChange(e?.toDate())}
                                />
                            )}
                            rules={{ required: false }}
                            name="validity.from"
                        />
                        <FieldErrorMessage errors={errors} name="validity.from" />
                    </Col>
                    <Col span={4}>
                        <label htmlFor={"validity.to"}>Valid To</label>
                        <Controller
                            control={control}
                            render={(
                                { onChange, onBlur, value, name }
                            ) => (
                                <DatePicker
                                    style={{ width: "100%" }}
                                    onBlur={onBlur}
                                    value={moment(value)}
                                    onChange={(e) => onChange(e?.toDate())}
                                />
                            )}
                            // as={<DatePicker  allowClear style={{ width: "100%" }} />}
                            rules={{ required: false }}
                            name="validity.to"
                        />
                        <FieldErrorMessage errors={errors} name="validity.to" />
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <label htmlFor={"appliesTo"}>Applies To</label>
                        <Controller
                            control={control}
                            as={<Select defaultValue={"appliesTo"}
                                options={
                                    [
                                        { label: "All Items", value: "allItems" },
                                        { label: "Specific Categories", value: "specificCategories" }]}
                                optionFilterProp="label"
                                allowClear style={{ width: "100%" }} />}
                            rules={{ required: false }}
                            name="appliesTo"
                        />
                        <FieldErrorMessage errors={errors} name="appliesTo" />
                    </Col>
                </Row>
                {
                    appliesTo === "specificCategories" &&
                    <Row>
                        <Col span={6}>
                            <label htmlFor={"categories"}>Categories</label>
                            <Controller
                                control={control}
                                as={<Select options={categories.map(c => ({ label: c.name, value: c._id }))} mode="multiple" optionFilterProp="label"
                                    allowClear style={{ width: "100%" }} />}
                                rules={{ required: false }}
                                name="categories"
                            />
                            <FieldErrorMessage errors={errors} name="categories" />
                        </Col>
                    </Row>

                }


                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"numberOfUses"}>Number Of Uses</label>
                        <Controller
                            control={control}
                            as={<Input allowClear id={"numberOfUses"} />}
                            rules={{ required: true }}
                            name="numberOfUses"
                        />
                        <FieldErrorMessage errors={errors} name="numberOfUses" />
                    </Col>

                </Row>
            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default PromoCodeForm;