import React from "react";
import { Link } from "react-router-dom";
import { Table } from "./Table";
import { Api } from "../api";
import { Space } from "antd";
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from "./BasicTableFilter";

const columns = [
  {
    title: "id",
    sorter: true,
    dataIndex: "_id",
    width: "20%",
    key: "_id",
    render: (text: string) => {
      return <Link to={`/stockTransfers/update/${text}`}>{text}</Link>;
    },
  },
  {
    title: "Item",
    dataIndex: "item",
    key: "item",
    sorter: true,
    width: "20%",
    render: (item: any) => {
      return item && item.name;
    },
  },
  {
    title: "From Warehouse",
    dataIndex: "from",
    key: "from",
    sorter: true,
    width: "20%",
    render: (from: any) => {
      return from.name;
    },
  },
  {
    title: "To Warehouse",
    dataIndex: "to",
    key: "to",
    sorter: true,
    width: "20%",
    render: (to: any) => {
      return to.name;
    },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    sorter: true,
    width: "20%",
  },
];

const StockTransferTable = () => {
  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Table
        tableId={"stockTransfersTable"}
        deleteApiCall={Api.stockTransfers.delete}
        allowDelete={true}
        restoreApiCall={Api.stockTransfers.restore}
        actions={(record: any) => <Link to={`/stockTransfers/update/${record._id}`}>View</Link>}
        Filter={BasicTableFilter}
        apiCall={Api.stockTransfers.table}
        columns={columns}
      />
    </Space>
  );
};

export default StockTransferTable;
