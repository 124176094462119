import { Select } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";

const DataSelector: React.FC<any> = ({
  mode = undefined,
  apiCall,
  nameField = "name",
  onChange,
  value,
  filterFunction,
}) => {
  const [state, setState]: any = useState({ data: [], loading: true });

  useEffect(() => {
    apiCall().then((res: any) => {
      setState({ loading: false, data: res.data });
    });
  }, [apiCall]);

  const getOptions = () => {
    let data: any = state.data.map((i: any) => ({
      label: i[nameField],
      value: i._id,
    }));
    if (filterFunction) data = data.filter(filterFunction);
    return data;
  };

  return (
    <Select
      mode={mode}
      style={{ width: "100%" }}
      onChange={onChange}
      value={value}
      options={getOptions()}
    />
  );
};

export default DataSelector;
