import React from "react";
import { Link } from "react-router-dom";
import { Table } from "./Table";
import { Api } from "../api";
import { Space } from "antd";
import BasicTableFilter from "./BasicTableFilter";
import { ImagesHelpers } from "../helpers/images";
import formatPrice from "../helpers/priceFormat";
import { Helpers } from "../helpers";
//import { CategoriesFilter } from './CategoriesFilter';

const columns = [
  {
    title: "id",
    sorter: true,
    dataIndex: "_id",
    key: "_id",
    render: (text: string) => {
      return <Link to={`/items/update/${text}`}>{text}</Link>;
    },
  },
  {
    title: "Image",
    dataIndex: "images",
    key: "images",
    width: "30%",
    align: "center" as "center",
    render: (images: any) => {
      if (images[0])
        return (
          <img
            width={100}
            height={100}
            style={{ objectFit: "contain" }}
            alt="item"
            src={ImagesHelpers.formatUrl(images[0])}
          />
        );
    },
  },

  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
  },
  {
    title: "Retail Price",
    dataIndex: "pricing",
    key: "pricing",
    sorter: true,
    render: (pricing: any) => {
      if (!pricing) return null;
      return formatPrice(pricing.retail, pricing.currency?.symbol);
    },
  },
  {
    title: "Currency",
    dataIndex: "pricing",
    key: "currency",
    sorter: true,
    render: (pricing: any) => {
      if (!pricing) return null;
      return pricing.currency?.name || "Lebanese Pound (UNSET)";
    },
  },
  {
    title: "updatedAt",
    dataIndex: "updatedAt",
    sorter: true,
    render: (date: Date) => {
      return <p title={Helpers.formatDateFull(date)}>{Helpers.formatDate(date)}</p>;
    },
  },
];

const ItemTable = () => {
  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Table
        tableId={"itemsTable"}
        deleteApiCall={Api.items.delete}
        allowDelete={true}
        restoreApiCall={Api.items.restore}
        actions={(record: any) => <Link to={`/items/update/${record._id}`}>View</Link>}
        Filter={BasicTableFilter}
        filterDefinitions={[
          {
            key: "_id",
            type: "number",
            label: "ID",
            span: 3,
          },
          {
            key: "name",
            type: "search",
            label: "Name",
          },
          {
            key: "brand",
            label: "Brand",
            type: "multiValue",
            apiCall: Api.brands.list,
            dataMappingFunction: (list: any) =>
              list.map((i: any) => ({ label: i.name, value: i._id })),
          },
          {
            key: "categories",
            label: "Category",
            type: "multiValue",
            apiCall: Api.categories.list,
            dataMappingFunction: (list: any) =>
              list.map((i: any) => ({ label: i.name, value: i._id })),
          },
        ]}
        apiCall={Api.items.table}
        columns={columns}
      />
    </Space>
  );
};

export default ItemTable;
