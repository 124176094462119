import { Button, Col, DatePicker, Input, InputNumber, message, Result, Row, Space, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";
import ItemStockInfo from "./ItemStockInfo";
import ItemSelector from "./stockEntry/ItemSelector";
import WarehouseSelector from "./WarehousesSelector";


const StockEntryForm = () => {
    let { id }: any = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset, watch } = useForm();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/stockEntries')
    }
    // const imageFields = [
    //     {
    //         name: "image",
    //         singleValue: true,
    //     }
    // ];

    const item = watch("item");

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.stockEntries.single, id, setValue: reset,
        //  imageFields
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.stockEntries.create,
            updateApiCall: Api.stockEntries.update,
            onSuccess,
            //  imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/stockEntries">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Typography.Title level={3}>Create New Stock Entry</Typography.Title>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={12}>
                        <label htmlFor={"item"}>Item</label>
                        <Controller
                            control={control}
                            as={<ItemSelector disabled={!!id} />}
                            rules={{ required: true }}
                            name="item"
                        />
                        <FieldErrorMessage errors={errors} name="item" />
                    </Col>
                </Row>
                <ItemStockInfo itemId={item} />
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"item"}>Warehouse</label>
                        <Controller
                            control={control}
                            as={<WarehouseSelector disabled={!!id} />}
                            rules={{ required: true }}
                            name="warehouse"
                        />
                        <FieldErrorMessage errors={errors} name="warehouse" />
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"quantity"}>Quantity</label>
                        <Controller
                            control={control}
                            as={<InputNumber disabled={!!id} style={{ width: "100%" }} />}
                            name="quantity"
                        />
                        <FieldErrorMessage errors={errors} name="quantity" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"batch"}>Batch #</label>
                        <Controller
                            control={control}
                            as={<Input />}
                            name="batch"
                        />
                        <FieldErrorMessage errors={errors} name="batch" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"expiryDate"}>Expiry Date</label>
                        <Controller
                            control={control}
                            render={({ onChange, onBlur, value }) => (
                                <DatePicker style={{ width: "100%" }}
                                    onChange={onChange}
                                    {...value ? {
                                        value: moment(value),
                                    } : {

                                    }}
                                />
                            )}

                            name="expiryDate"
                        />
                        <FieldErrorMessage errors={errors} name="expiryDate" />
                    </Col>
                </Row>
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"supplier"}>Supplier</label>
                        <Controller
                            control={control}
                            as={<Input />}
                            name="supplier"
                        />
                        <FieldErrorMessage errors={errors} name="supplier" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"supplier"}>Invoice #</label>
                        <Controller
                            control={control}
                            as={<Input />}
                            name="invoiceNumber"
                        />
                        <FieldErrorMessage errors={errors} name="invoiceNumber" />
                    </Col>
                </Row>

                <Row gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"itemCost"}>Cost Per Item #</label>
                        <Controller
                            control={control}
                            as={<InputNumber style={{ width: "100%" }} />}
                            name="itemCost"
                        />
                        <FieldErrorMessage errors={errors} name="itemCost" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"totalCost"}>Total Cost</label>
                        <Controller
                            control={control}
                            as={<InputNumber style={{ width: "100%" }} />}
                            name="totalCost"
                        />
                        <FieldErrorMessage errors={errors} name="totalCost" />
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={12}>
                        <label htmlFor={"notes"}>Notes</label>
                        <Controller
                            control={control}
                            as={<TextArea rows={3} style={{ width: "100%" }} />}
                            name="notes"
                        />
                        <FieldErrorMessage errors={errors} name="notes" />
                    </Col>
                </Row>
            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default StockEntryForm;