import { PlusOutlined } from "@ant-design/icons";
import { Divider, Input, Select } from "antd";
import React, { useState } from "react";
import { Api } from "../api";
import { useBrandsList } from "../api/useBrandsList";

const BrandsSelector: React.FC<any> = ({ value, onChange }) => {
  const [brands, loading, reloadBrands] = useBrandsList();
  const [creating, setIsCreating] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const onAddBrand = () => {
    if (inputValue.trim() !== "" && !creating) {
      setIsCreating(true);
      Api.brands
        .create({ name: inputValue })
        .then(() => {
          setInputValue("");
          setIsCreating(false);
          reloadBrands();
        })
        .catch(() => {
          setInputValue("");
          setIsCreating(false);
        });
    }
  };

  return (
    <Select
      showSearch
      optionFilterProp="label"
      value={value}
      onChange={onChange}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
            <Input
              disabled={creating}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              size="small"
              style={{ flex: "auto" }}
            />
            <div
              onClick={onAddBrand}
              style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
            >
              <PlusOutlined /> Add Brand
            </div>
          </div>
        </div>
      )}
      loading={loading}
      style={{ width: "100%" }}
      options={brands.map((i) => ({
        label: i.name,
        value: i._id,
        key: i._id,
      }))}
    />
  );
};

export default BrandsSelector;
