import { Button, Col, Input, InputNumber, message, Result, Row, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";
import ItemStockInfo from "./ItemStockInfo";
import ItemSelector from "./stockEntry/ItemSelector";
import WarehouseSelector from "./WarehousesSelector";


const StockTransferForm = () => {
    let { id }: any = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, watch, reset } = useForm();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/stockTransfers')
    }
    // const imageFields = [
    //     {
    //         name: "image",
    //         singleValue: true,
    //     }
    // ];

    const [notFound, loading] = useLoadFormData({
        apiCall: Api.stockTransfers.single, id, setValue: reset
        //  imageFields
    });
    const item = watch("item");
    const from = useWatch({ control, name: "from" });
    const to = useWatch({ control, name: "to" });
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.stockTransfers.create,
            updateApiCall: Api.stockTransfers.update,
            onSuccess,
            //  imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/stockTransfers">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
            <Space size="middle" style={{ width: "100%" }} direction="vertical">
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={12}>
                        <label htmlFor={"item"}>Item</label>
                        <Controller
                            control={control}
                            as={<ItemSelector disabled={!!id} />}
                            rules={{ required: true }}
                            name="item"
                        />
                        <FieldErrorMessage errors={errors} name="item" />
                    </Col>


                </Row>
                <ItemStockInfo itemId={item} />
                <Row>
                    <Col span={6}>
                        <label htmlFor={"quantity"}>Quantity</label>
                        <Controller
                            control={control}
                            as={<InputNumber disabled={!!id} style={{ width: "100%" }} />}
                            rules={{ required: true }}
                            name="quantity"
                        />
                        <FieldErrorMessage errors={errors} name="quantity" />
                    </Col>

                </Row>

                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"from"}>From Warehouse</label>
                        <Controller
                            control={control}
                            as={<WarehouseSelector exclude={[to]} disabled={!!id} />}
                            rules={{ required: true }}
                            name="from"
                        />
                        <FieldErrorMessage errors={errors} name="from" />
                    </Col>
                    <Col span={6}>
                        <label htmlFor={"from"}>To Warehouse</label>
                        <Controller
                            control={control}
                            as={<WarehouseSelector exclude={[from]} disabled={!!id} />}
                            rules={{ required: true }}
                            name="to"
                        />
                        <FieldErrorMessage errors={errors} name="to" />
                    </Col>

                </Row>
                <Row>
                    <Col span={12}>
                        <label htmlFor={"notes"}>Notes</label>
                        <Controller
                            control={control}
                            as={<TextArea rows={3} />}
                            name="notes"
                        />
                        <FieldErrorMessage errors={errors} name="notes" />
                    </Col>
                </Row>
            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default StockTransferForm;