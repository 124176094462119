import { Select } from "antd";
import React from "react";
import { useDriversList } from "../../hooks/useDriversList";

const OrderDriverSelector: any = ({ onChange, value }: any) => {
  const [users, loading]: any = useDriversList();

  console.log(users);

  return (
    <Select
      onChange={onChange}
      value={value}
      style={{ width: "100%" }}
      loading={loading}
      options={users?.map((user: any) => ({
        value: user._id,
        label: `${user.firstName} ${user.lastName}`,
      }))}
    />
  );
};

export default OrderDriverSelector;
