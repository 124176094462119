import { Button, Col, Input, message, Result, Row, Space } from "antd";
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";


const BrandForm = () => {
    let { id }: any = useParams();
    // const [notFound, setNotFound] = useState(false);
    const { handleSubmit, errors, control, reset } = useForm();
    let history = useHistory();
    const onSuccess = () => {
        message.success("Saved");
        history.push('/brands')
    }
   // const imageFields = [
   //     {
   //         name: "image",
   //         singleValue: true,
   //     }
   // ];
   
    const [notFound, loading] = useLoadFormData({
        apiCall: Api.brands.single, id, setValue: reset, 
      //  imageFields
    })
    const [loadingSubmit, submit] = useSubmitForm(
        {
            createApiCall: Api.brands.create,
            updateApiCall: Api.brands.update,
            onSuccess,
          //  imageFields,
            updateId: id,
        });

    if (notFound)
        return (<Result
            status="404"
            title="404"
            subTitle="Sorry, document does not exist"
            extra={<Button type="primary">

                <Link to="/brands">Back to list</Link>
            </Button>}
        />)


    return (
        <form className="white-container" onSubmit={handleSubmit(submit)}>
           <Space size="middle" style={{width: "100%"}} direction="vertical">
                <Row style={{ width: "100%" }} gutter={12}>
                    <Col span={6}>
                        <label htmlFor={"name"}> Name</label>
                        <Controller
                            control={control}
                            as={<Input id={"name"} />}
                            rules={{ required: true }}
                            name="name"
                        />
                        <FieldErrorMessage errors={errors} name="name" />
                    </Col>

                </Row>
               
            </Space>
            <div style={{ marginBottom: 10 }} />
            <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>Submit</Button>
        </form>);
};

export default BrandForm;