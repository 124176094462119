import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { Api } from '../api';


const WarehouseSelector: React.FC<any> = ({ exclude = [], ...props }) => {
    const [state, setState] = useState({ loading: true, data: [] });
    const [creating, setIsCreating] = useState(false);
    const [inputValue, setInputValue] = useState("");


    const execludeValue = JSON.stringify(exclude);
    const fetchData = useCallback(() => {
        Api.warehouses.list().then((res) => {
            setState({ loading: false, data: res.data.filter((i: any) => !exclude?.includes(i._id)) })
        });
    }, [execludeValue]);

    useEffect(() => {
        fetchData();
    }, [fetchData, execludeValue]);

    const onAddWarehouse = () => {

        if (inputValue.trim() !== "" && !creating) {
            setIsCreating(true);
            Api.warehouses.create({ name: inputValue }).then(() => {
                setInputValue("");
                setIsCreating(false);
                fetchData();
            }).catch(() => {
                setInputValue("");
                setIsCreating(false);
            });
        }
    };

    return (
        <Select
            showSearch
            optionLabelProp="label"
            allowClear
            dropdownRender={menu => (
                <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input disabled={creating} value={inputValue} onChange={(e) => setInputValue(e.target.value)} size="small" style={{ flex: 'auto' }} />
                        <div
                            onClick={onAddWarehouse}
                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}

                        >
                            <PlusOutlined /> Add Warehouse
                </div>
                    </div>
                </div>
            )}
            style={{ width: "100%" }}
            loading={state.loading}
            options={state.data.map((i: any) => ({ label: i.name, value: i._id }))}
            {...props}
        />
    )
};

export default WarehouseSelector;