import { useEffect, useState } from 'react';
import { Api } from '.';


export const useBrandsList: () => [Record<string, any>[], boolean, any] = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const loadData = () => {
        setLoading(true);
        Api.brands.list().then((res) => {
            setData(res.data);
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        })
    }
    useEffect(() => {
        loadData();
    }, []);

    return [data, loading, loadData];
}