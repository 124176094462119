import { Select } from "antd";
import React, { useEffect, useState } from "react";

const SelectWithApiData: React.FC<{
  value?: any;
  onChange?: any;
  apiCall: any;
  dataMappingFunction: any;
  options: any[];
}> = ({ value, onChange, apiCall, dataMappingFunction, options = [] }) => {
  const [state, setState] = useState({ data: options, loading: false });
  useEffect(() => {
    if (apiCall) {
      setState({ ...state, loading: true });
      apiCall().then((res: any) => {
        setState({ data: res.data, loading: false });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Select
      showSearch
      allowClear
      optionFilterProp="label"
      style={{ width: "100%" }}
      value={value}
      options={dataMappingFunction ? dataMappingFunction(state.data || []) : state.data}
      onChange={onChange}
    />
  );
};

export default SelectWithApiData;
