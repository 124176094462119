import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select } from 'antd';
import React, { useState } from 'react';
import { Api } from '../../api';
import { useCategoriesList } from '../../api/useCategoriesList';


const CategorySelector: React.FC<any> = ({ value, onChange, single = false }) => {

    const [categories, loading, reloadCategories] = useCategoriesList();
    const [creating, setIsCreating] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const onAddCategory = () => {

        if (inputValue.trim() !== "" && !creating) {
            setIsCreating(true);
            Api.categories.create({ name: inputValue }).then(() => {
                setInputValue("");
                setIsCreating(false);
                reloadCategories();
            }).catch(() => {
                setInputValue("");
                setIsCreating(false);
            });
        }
    };

    return (
        <Select
            mode={single ? undefined : "multiple"}
            value={value}
            onChange={onChange}
            allowClear
            showSearch
            optionFilterProp="label"

            dropdownRender={menu => (
                <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                        <Input disabled={creating} value={inputValue} onChange={(e) => setInputValue(e.target.value)} size="small" style={{ flex: 'auto' }} />
                        <div
                            onClick={onAddCategory}
                            style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}

                        >
                            <PlusOutlined /> Add Category
                </div>
                    </div>
                </div>
            )}

            loading={loading} style={{ width: "100%" }} options={
                categories.map(i => ({
                    label: i.name,
                    value: i._id,
                    key: i._id
                }))
            } />
    )
};

export default CategorySelector;