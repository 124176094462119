import { Select, Spin } from 'antd';
import React, { useEffect } from 'react';
import debounce from 'lodash/debounce';
import { Api } from '../../api';


const ItemSelector = ({ ...props }) => {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const fetchRef = React.useRef(0);
    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);


            Api.items.search(value, props.value || null).then((res) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }

                setOptions(res.data.map((i: any) => ({ label: `${i.name} - ${i.barcode}`, value: i._id })));
                setFetching(false);
            });
        };

        return debounce(loadOptions, 800);
    }, []);


    useEffect(() => {
        if (props.value && options.length === 0)
            Api.items.search("", props.value).then((res) => {

                setOptions(res.data.map((i: any) => ({ label: `${i.name} - ${i.barcode}`, value: i._id })));
                setFetching(false);
            });
    }, [props.value])




    return (
        <Select
            placeholder="Start Typing to search for items or scan barcode"
            showSearch
            style={{ width: "100%" }}
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            {...props}
        />
    )
};


export default ItemSelector;