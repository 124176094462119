import { Button, Col, Input, InputNumber, message, Result, Row, Space, Typography } from "antd";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { useCategoriesList } from "../hooks/useCategoriesList";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { useTagsList } from "../hooks/useTagsList";
import BrandsSelector from "./brandsSelector";
import CategorySelector from "./categories/CategorySelector";
import DataSelector from "./DataSelector";
import { FieldErrorMessage } from "./FieldErrorMessage";
import FileUploadV2 from "./fileUploadV2/FileUploadV2";
import { TagsInput } from "./TagsInput";
import TextEditorInput from "./TextEditorInput";

const ItemForm = () => {
  let { id }: any = useParams();
  // const [notFound, setNotFound] = useState(false);
  const { handleSubmit, errors, control, reset, watch, setValue } = useForm({
    defaultValues: { options: [{}], images: [] },
  });

  let history = useHistory();
  const [categories, loadingCategories] = useCategoriesList();
  const [tags, loadingTags] = useTagsList();
  const onSuccess = () => {
    message.success("Saved");
    history.push("/items");
  };
  const imageFields = [
    {
      name: "images",
      singleValue: false,
    },
  ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.items.single,
    id,
    setValue: reset,
    // imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.items.create,
    updateApiCall: Api.items.update,
    onSuccess,
    // imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="primary">
            <Link to="/items">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Space size="middle" style={{ width: "100%" }} direction="vertical">
        <div className="white-container">
          <Typography.Title level={4}>Basic Info</Typography.Title>

          <Space size="middle" style={{ width: "100%" }} direction="vertical">
            <Row style={{ width: "100%" }} gutter={12}>
              <Col span={6}>
                <label htmlFor={"name"}>Name</label>
                <Controller
                  control={control}
                  as={<Input id={"name"} />}
                  rules={{ required: true }}
                  name="name"
                />
                <FieldErrorMessage errors={errors} name="name" />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={6}>
                <label htmlFor={"sku"}>SKU</label>
                <Controller
                  control={control}
                  as={<Input id={"sku"} />}
                  rules={{ required: false }}
                  name="sku"
                />
                <FieldErrorMessage errors={errors} name="sku" />
              </Col>
              <Col span={6}>
                <label htmlFor={"barcode"}>Barcode</label>
                <Controller
                  control={control}
                  as={<Input id={"barcode"} />}
                  rules={{ required: true }}
                  name="barcode"
                />
                <FieldErrorMessage errors={errors} name="barcode" />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={6}>
                <label htmlFor={"size"}>Size</label>
                <Controller
                  control={control}
                  as={<InputNumber style={{ width: "100%" }} id={"size"} />}
                  rules={{ required: false }}
                  name="size"
                />
                <FieldErrorMessage errors={errors} name="size" />
              </Col>
              <Col span={6}>
                <label htmlFor={"unit"}>Unit</label>
                <Controller
                  control={control}
                  as={<Input id={"unit"} placeholder="ml" />}
                  rules={{ required: true }}
                  name="unit"
                />
                <FieldErrorMessage errors={errors} name="unit" />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label htmlFor={"description"}>Description</label>
                <Controller
                  control={control}
                  as={TextEditorInput}
                  rules={{ required: false }}
                  name="description"
                />
                <FieldErrorMessage errors={errors} name="description" />
              </Col>
              <Col span={12}>
                <label htmlFor={"images"}>Images</label>
                <Controller
                  control={control}
                  as={<FileUploadV2 limit={5} />}
                  // rules={{
                  //     validate: ImagesHelpers.validate
                  // }}
                  name="images"
                />
                <FieldErrorMessage errors={errors} name="images" />
              </Col>
            </Row>
          </Space>
        </div>

        <div className="white-container">
          <Typography.Title level={4}>Categorization</Typography.Title>

          <Space size="middle" style={{ width: "100%" }} direction="vertical">
            <Row gutter={6}>
              <Col span={6}>
                <label htmlFor={"categories"}>Categories</label>
                <Controller
                  control={control}
                  as={<CategorySelector />}
                  rules={{
                    required: true,
                  }}
                  name="categories"
                />
                <FieldErrorMessage errors={errors} name="categories" />
              </Col>
              <Col span={6}>
                <label htmlFor={"brand"}>Brand</label>
                <Controller
                  control={control}
                  as={<BrandsSelector />}
                  rules={{
                    required: true,
                  }}
                  name="brand"
                />
                <FieldErrorMessage errors={errors} name="brand" />
              </Col>
              <Col span={6}>
                <label htmlFor={"tags"}>Tags</label>
                <Controller
                  control={control}
                  as={<TagsInput style={{ width: "100%" }} tags={tags} />}
                  rules={{
                    required: true,
                  }}
                  name="tags"
                />
                <FieldErrorMessage errors={errors} name="tags" />
              </Col>
            </Row>
          </Space>
        </div>

        <div className="white-container">
          <Typography.Title level={4}>Pricing</Typography.Title>

          <Space size="middle" style={{ width: "100%" }} direction="vertical">
            <Col span={6}>
              <label htmlFor={"pricing.currency"}> Currency</label>
              <Controller
                control={control}
                as={<DataSelector apiCall={Api.currencies.list} />}
                rules={{ required: true }}
                name="pricing.currency"
              />
              <FieldErrorMessage errors={errors} name="pricing.currency" />
            </Col>
            <Row gutter={6}>
              <Col span={6}>
                <label htmlFor={"pricing.retail"}>Retail</label>
                <Controller
                  control={control}
                  as={
                    <InputNumber
                      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                      style={{ width: "100%" }}
                      id={"pricing.retail"}
                    />
                  }
                  rules={{ required: true }}
                  name="pricing.retail"
                />
                <FieldErrorMessage errors={errors} name="pricing.retail" />
              </Col>
              <Col span={6}>
                <label htmlFor={"pricing.compareAtPrice"}>Compare At Price</label>
                <Controller
                  control={control}
                  formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
                  as={<InputNumber style={{ width: "100%" }} id={"pricing.compareAtPrice"} />}
                  rules={{ required: false }}
                  name="pricing.compareAtPrice"
                />
                <FieldErrorMessage errors={errors} name="pricing.compareAtPrice" />
              </Col>
              <Col span={6}>
                <label htmlFor={"pricing.cost"}>Cost</label>
                <Controller
                  control={control}
                  formatter={(value: any) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value: any) => value!.replace(/\$\s?|(,*)/g, "")}
                  as={<InputNumber style={{ width: "100%" }} id={"pricing.cost"} />}
                  rules={{ required: true }}
                  name="pricing.cost"
                />
                <FieldErrorMessage errors={errors} name="pricing.cost" />
              </Col>
            </Row>
          </Space>
        </div>
        <div className="white-container">
          <Typography.Title level={4}>Application Options</Typography.Title>

          <Space size="middle" style={{ width: "100%" }} direction="vertical">
            <Row gutter={6}>
              <Col span={6}>
                <label htmlFor={"maximumPurchaseQuantity"}>Maximum Purchase Quantity</label>
                <Controller
                  control={control}
                  as={
                    <InputNumber
                      defaultValue={10}
                      style={{ width: "100%" }}
                      id={"maximumPurchaseQuantity"}
                    />
                  }
                  rules={{ required: true }}
                  name="maximumPurchaseQuantity"
                />
                <FieldErrorMessage errors={errors} name="maximumPurchaseQuantity" />
              </Col>
            </Row>
          </Space>
        </div>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>
        Submit
      </Button>
    </form>
  );
};

export default ItemForm;
