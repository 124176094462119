import { Button, Col, Input, InputNumber, message, Result, Row, Space } from "antd";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { Api } from "../api";
import { useSubmitForm } from "../helpers/forms";
import { ImagesHelpers } from "../helpers/images";
import { useLoadFormData } from "../hooks/useLoadFormData";
import { FieldErrorMessage } from "./FieldErrorMessage";
import { FileUploader } from "./FileUploader";

const CurrencyForm = () => {
  let { id } = useParams();
  // const [notFound, setNotFound] = useState(false);
  const { handleSubmit, errors, control, reset } = useForm();
  let history = useHistory();
  const onSuccess = () => {
    message.success("Saved");
    history.push("/currencies");
  };
  // const imageFields = [
  //     {
  //         name: "image",
  //         singleValue: true,
  //     }
  // ];

  const [notFound, loading] = useLoadFormData({
    apiCall: Api.currencies.single,
    id,
    setValue: reset,
    //  imageFields
  });
  const [loadingSubmit, submit] = useSubmitForm({
    createApiCall: Api.currencies.create,
    updateApiCall: Api.currencies.update,
    onSuccess,
    //  imageFields,
    updateId: id,
  });

  if (notFound)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, document does not exist"
        extra={
          <Button type="primary">
            <Link to="/currencies">Back to list</Link>
          </Button>
        }
      />
    );

  return (
    <form className="white-container" onSubmit={handleSubmit(submit)}>
      <Space size="middle" style={{ width: "100%" }} direction="vertical">
        <Row style={{ width: "100%" }} gutter={12}>
          <Col span={6}>
            <label htmlFor={"name"}>Name</label>
            <Controller
              control={control}
              as={<Input id={"name"} />}
              rules={{ required: true }}
              name="name"
            />
            <FieldErrorMessage errors={errors} name="name" />
          </Col>
          <Col span={6}>
            <label htmlFor={"symbol"}>Symbol</label>
            <Controller
              control={control}
              as={<Input id={"symbol"} />}
              rules={{ required: true }}
              name="symbol"
            />
            <FieldErrorMessage errors={errors} name="symbol" />
          </Col>
          <Col span={6}>
            <label htmlFor={"rate"}>Rate</label>
            <Controller
              control={control}
              as={<InputNumber style={{ width: "100%" }} min={0} id={"rate"} />}
              rules={{ required: true }}
              name="rate"
            />
            <FieldErrorMessage errors={errors} name="rate" />
          </Col>
        </Row>
      </Space>
      <div style={{ marginBottom: 10 }} />
      <Button loading={loading || loadingSubmit} htmlType="submit" role={"submit"}>
        Submit
      </Button>
    </form>
  );
};

export default CurrencyForm;
