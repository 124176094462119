import React from "react";
import { Link } from "react-router-dom";
import { Table } from "./Table";
import { Api } from "../api";
import { Space } from "antd";
//import { CategoriesFilter } from './CategoriesFilter';
import BasicTableFilter from "./BasicTableFilter";

const columns = [
  {
    title: "id",
    sorter: true,
    dataIndex: "_id",
    width: "20%",
    key: "_id",
    render: (text: string) => {
      return <Link to={`/stockEntries/update/${text}`}>{text}</Link>;
    },
  },
  {
    title: "Item",
    dataIndex: "item",
    key: "name",
    width: "20%",
    render: (item: any) => {
      return item ? item.name : "";
    },
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: "20%",
  },
  {
    title: "Total Cost",
    dataIndex: "totalCost",
    key: "totalCost",
    width: "20%",
  },
];

const StockEntryTable = () => {
  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Table
        tableId={"stockEntriesTable"}
        deleteApiCall={Api.stockEntries.delete}
        allowDelete={true}
        restoreApiCall={Api.stockEntries.restore}
        actions={(record: any) => <Link to={`/stockEntries/update/${record._id}`}>View</Link>}
        Filter={BasicTableFilter}
        apiCall={Api.stockEntries.table}
        columns={columns}
      />
    </Space>
  );
};

export default StockEntryTable;
