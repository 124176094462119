import { Tabs } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import ItemForm from '../ItemForm';
import ItemStockInfo from '../ItemStockInfo';
import ItemVariationEditor from './ItemVariationEditor';


const ItemSinglePage = () => {
    let { id }: any = useParams();
    return (
        <Tabs defaultActiveKey={"1"}>
            <Tabs.TabPane key={"1"} tab="Basic">
                <ItemForm />
            </Tabs.TabPane>

            <Tabs.TabPane key={"2"} tab="Stock">
                <div className="white-container">
                    <ItemStockInfo itemId={id ? parseInt(id) : undefined} />
                </div>
            </Tabs.TabPane>

        </Tabs>
    )
};

export default ItemSinglePage;